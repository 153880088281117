export const defaultChoice = { value: -1, name: "Select one" }
export const admin = { value: 0, name: "Admin" };
export const planner = { value: 1, name: "Planner" };
export const download = { value: 2, name: "Download" };
export const downloaderAdmin = { value: 3, name: "Downloader Admin" };
export const billing = { value: 4, name: "Billing" };
export const receivedBilling = { value: 5, name: "Received Billing" };

export const adminRoleSet = [ defaultChoice, admin, planner, download, billing, receivedBilling ];
export const downloaderAdminRoleSet = [ defaultChoice, download, billing, downloaderAdmin ];

export function determineRoleOptions(userRole) {
    if (userRole === "AD") {
        return adminRoleSet;
    }
    else if (userRole === "DAD") {
        return downloaderAdminRoleSet;
    }
    else {
        return [];
    }
}