import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    Typography,
    Chip,    
    Modal,
    Hidden
} from "@mui/material";
import {
    CheckCircle,
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import RecievedEstimateViewer from "./RecievedEstimateViewer";
import SplitToken from '../../mixins/SplitToken';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function EstimatesToMeTable(){
    const user = SplitToken();
    /// used for showing invoice 
    // const companyInvoices = useSelector((state) => state.CompanyReducer.allCompanyInvoices);
    // const companyInvoices = useSelector((state) => state.CompanyReducer.allCompanyInvoices.invoicesDTO);
    const foundInvoiceCount = useSelector((state) => state.UserReducer.allUserInvoices.foundTotalInvoiceCount);
    const invoiceCount = useSelector((state) => state.UserReducer.allUserInvoices.totalInvoiceCount);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const files = useSelector((state) => state.FileReducer.allFiles.filesDTO);
    const userInvoices = useSelector((state) => state.UserReducer.allUserInvoices.invoicesDTO);
    const customerFiles = useSelector((state) => state.FileReducer.allCustomerFiles);
    const allMessages = useSelector((state) => state.UserReducer.allUserMessages);
    const dispatch = useDispatch();
    const userdata = useSelector((state)=> state.UserReducer.user);
    const isLoading = useSelector((state) => state.UserReducer.isDataLoading);
    console.log(userInvoices);

    const startDateInitialState = dayjs().subtract(1, "years").startOf('day');
    const endDateInitialState = dayjs().endOf('day');
    const [startDate, setStartDate] = React.useState(startDateInitialState);
    const [endDate, setEndDate] = React.useState(endDateInitialState);
    const [exportStartDate, setExportStartDate] = React.useState(dayjs(startDateInitialState).format());
    const [exportEndDate, setExportEndDate] = React.useState(dayjs(endDateInitialState).format());

    function resetDate(){
        setStartDate(startDateInitialState);
        setEndDate(endDateInitialState);
        setExportStartDate(startDateInitialState);
        setExportEndDate(endDateInitialState);
        dispatch({ type: 'GET_CUSTOMER_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: user.id, startDate: startDateInitialState, endDate: endDateInitialState }});
    };

    const [paginationModel, setPaginationModel] = React.useState({pageSize: 25, page: 0});
    const rowCountRef = React.useRef(foundInvoiceCount || 0);
    
    const rowCount = React.useMemo(() => {
      if (foundInvoiceCount !== undefined) {
        rowCountRef.current = foundInvoiceCount;
      }
      return rowCountRef.current;
    }, [foundInvoiceCount]);

    function filterDate(){
        dispatch({ type: 'GET_CUSTOMER_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: user.id, startDate: exportStartDate, endDate: exportEndDate }})
    }

    const [invoiceModelObj, setInvoiceModelObj] = React.useState({});
    const [invoiceModal, setInvoiceModal] = React.useState(false);
    const handleInvoiceOpen = () => setInvoiceModal(true);
    const handleInvoiceClose = () => setInvoiceModal(false);
    function showInvoice(x){
        setInvoiceModelObj({
            // id: x?.id,
            // fileName: x?.fileName,
            // companyId: x?.fileCompanyId,
            // isProcessed: x?.isProcessed,
            // url: x?.url,
            // version: x?.version,
            // reportUrl: x?.reportUrl,
            // created: x?.created,
            // updated: x?.updated,
            // deleted: x?.deleted,
            // summaryUrl: x?.summaryUrl,
            // paidFileUrl: x?.paidFileUrl,
            // isAccepted: x?.isAccepted,
            // isPaidFor: x?.isPaidFor,
            // sequencingErrorCode: x?.sequencingErrorCode,
            // paidTextUrl: x?.paidTextUrl,
            // pointCount: x?.pointCount,
            // estimateId: x?.estimateId,
            // dwgUrl: x?.dwgUrl,
            // pointList: x?.pointList,

            // openInvoice: x?.openInvoice,
            // isSent: x?.isSent,
            // isInvoiceAccepted: x?.isInvoiceAccepted,
            // invoiceCreated: x?.invoiceCreated,
            // invoiceCustomerId: x?.invoiceCustomerId,
            // invoiceDeleted: x?.invoiceDeleted,
            // invoiceDeveloperId: x?.invoiceDeveloperId,
            // invoiceId: x?.invoiceId,
            // invoiceValues: x?.invoiceValues,
            // invoiceJobId: x?.invoiceJobId,
            // invoiceUpdated: x?.invoiceUpdated, 

            // customerStatus: x?.customerStatus,
            // companyContact: x?.companyContact,
            // companyStreet: x?.companyStreet,
            // companyCity: x?.companyCity,
            // companyState: x?.companyState,
            // companyZip: x?.companyZip,
            // companyCountry: x?.companyCountry,

            created: x?.created,
            customerEmail: x?.customerEmail,
            customerId: x?.customerId,
            customerName: x?.customerName,
            developerId: x?.developerId,
            developerName: x?.developerName,
            estimateId: x?.estimateId,
            fileCompanyId: x?.fileCompanyId,
            fileId: x?.fileId,
            fileIsAccepted: x?.fileIsAccepted,
            fileName: x?.fileName,
            id: x?.id,
            invoiceValues: x?.invoiceValues,
            isInvoiceAccepted: x?.isInvoiceAccepted,
            isPaidFor: x?.isPaidFor,
            isProcessed: x?.isProcessed,
            isSent: x?.isSent,
            jobId: x?.jobId,
            openInvoice: x?.openInvoice,
            pointCount: x?.pointCount,
            reportUrl: x?.reportUrl,
            sequencingErrorCode: x?.sequencingErrorCode,
            summaryUrl: x?.summaryUrl,
            updated: x?.updated,
            pointList: x?.pointList,

        })
        handleInvoiceOpen();
    };

    /// For Creating and Downloading Invoice as CSV //
    function exportToCsv() {
        let csvDownloadable = [];
        let exportECU = 0;
        let exportFinalTotal = 0;
        let exportStartDateFormat = dayjs(exportStartDate).format('MM/DD/YYYY');
        let exportEndDateFormat = dayjs(exportEndDate).format('MM/DD/YYYY');

        for (let i = 0; i < userInvoices.length; i++){
            let data = [];
            let invoiceTotal = 0;
            let taxTotal = 0;
            let finalTotal = 0;
            if (userInvoices[i]?.invoiceValues != undefined) {
                data = JSON?.parse(userInvoices[i]?.invoiceValues)
        
                for (let i = 0; i < data.items.length; i++) {
                    invoiceTotal += data.items[i].total;
                }
                console.log('total', invoiceTotal)
                for (let i = 0; i < data.taxes.length; i++) {
                    taxTotal += Number(data.taxes[i].total);
                }
                finalTotal = taxTotal+invoiceTotal;

                exportFinalTotal += finalTotal;
                
                if(data.pointTypeTotal){
                    exportECU += data.pointTypeTotal;
                }
            };

            if(userInvoices[i].developerName == company.name){
                csvDownloadable.push(
                    [`${data.date}`,`Botmark: ${userInvoices[i].fileName.split('.')[0]}`,`${data?.pointTypeTotal}`,`${finalTotal}`]
                )
            } else {
                csvDownloadable.push(
                    [`${data.date}`,`${userInvoices[i].developerName}: ${userInvoices[i].fileName.split('.')[0]}`,`${data?.pointTypeTotal}`,`${finalTotal}`]
                )
            }
        }

        csvDownloadable.push(
            [`${exportStartDateFormat} - ${exportEndDateFormat}`,`${exportECU}`,`${exportFinalTotal}`]
        );

        // let csvContent = "data:text/csv;charset=utf-8," 
        // + csvDownloadable.map(e => e.join(",")).join("\n");
        let csvContent = csvDownloadable.map(e => e.join(",")).join("\n");
    
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            // if(customerSelect != "00000000-0000-0000-0000-000000000000"){
            //     navigator.msSaveBlob(blob, `${customerSelect}-${exportStartDate}-${exportEndDate}`);
            // } else {
                navigator.msSaveBlob(blob, `allReceivedEstimates-${exportStartDateFormat}-${exportEndDateFormat}`);
            // }
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                // if(customerSelect != "00000000-0000-0000-0000-000000000000"){
                    // link.setAttribute("download", `${customerSelect}-${exportStartDate}-${exportEndDate}`);
                // } else {
                    link.setAttribute("download", `allReceivedEstimates-${exportStartDateFormat}-${exportEndDateFormat}`);
                // }
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    React.useEffect(() => {
        dispatch({ type: 'GET_CUSTOMER_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: user.id, startDate: exportStartDate, endDate: exportEndDate }});
        dispatch({ type: 'GET_CUSTOMER_FILES'});
        dispatch({ type: 'GET_COMPANY_FILES', payload: company?.id });
        dispatch({ type: 'FETCH_USER_MESSAGES', payload: user?.id });
    },[company]);

    const columns = [
        { field: 'developerName', headerName: 'Sender', flex: .7, headerClassName: 'header', editable: false,renderCell: (params) =>{
            if (params.row.developerName == company.name){
                return <span>Botmark</span>
            } else {
                return <span>{params.row.developerName}</span>
            }
        }},
        { field: 'fileName', headerName: 'Project Name', flex: 1, headerClassName: 'header', editable: false},
        { field: 'created', headerName: 'Created', flex: 1, headerClassName: 'header', renderCell: (params) => {
            let date = new Date(params.row.created);
            return date.toLocaleString()
        }},
        { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Status(params.row)
        )},
        { field: 'invoice', headerName: 'Estimate', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Invoice(params.row)
        )}
    ];
    function Status(x) {
            return <Chip icon={<CheckCircle/>} variant="contained" color="success" label="COMPLETE"/>
    };
    
    function Invoice(x) {
            return (
                // download invoice
                <div>       
                    <Button variant="contained" color="success" onClick={() => showInvoice(x)}>
                        View/ Download
                    </Button>
                </div>
            )
    }
    const rows = userInvoices;
    // console.log("INVOICES", invoices);

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='40%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };


    return (
        <Box
            sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '70vh' }}
        >
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography textAlign={"center"} color={"blue"} variant='h4'>
                    Received Estimates
                </Typography>
                <Typography textAlign={"center"}>
                    Total Estimates: {invoiceCount}
                </Typography>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box style={{display:'flex', flexDirection:'row'}}>
                    <Box sx={{ m: 1 }}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            className='DatePicker'
                            label="Start Date"
                            value={startDate}
                            onChange={ (selectedValue) => {
                                setStartDate(dayjs(selectedValue).startOf('day'));
                                setExportStartDate(dayjs(selectedValue).startOf('day').format());
                            }}
                            renderInput={ (params) => <TextField {...params} />}
                        />
                    </Box>

                    <Box sx={{ m: 1 }} >
                        <DatePicker
                            className='DatePicker'
                            label="End Date"
                            value={endDate}
                            onChange={ (selectedValue) => {
                                setEndDate(dayjs(selectedValue).endOf('day'));
                                setExportEndDate(dayjs(selectedValue).endOf('day').format());
                            }}
                            renderInput={ (params) => <TextField {...params} />}
                        />
                    </Box>
                    <Button variant='contained' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{filterDate()}}>Filter Date Range</Button>
                    <Button variant='outlined' color='warning' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{resetDate()}}>Reset Date Range</Button>
                    <Button variant='contained' color='secondary' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{exportToCsv()}}>Export Estimate Data</Button>
                </Box>
            </LocalizationProvider>
            <DataGrid
                columns={columns}
                rows={rows}
                components={{
                    Toolbar: CustomToolBar
                }}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <Modal 
                className="invoiceModal" 
                open={invoiceModal} 
                onClose={handleInvoiceClose}
                hideBackdrop={true}    
            >
                <div>
                    <Button onClick={() => setInvoiceModal(false)}>Close</Button>
                    <RecievedEstimateViewer className="estimateDiv" item={invoiceModelObj} handleInvoiceClose={handleInvoiceClose}/>
                </div>
            </Modal>
        </Box>
    )
}

export default EstimatesToMeTable;