import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
} from "@mui/material";
import { determineRoleOptions } from "./userData"
import "./Users.css"


function UserRoleChange({item, style}){
    /// allow for processor to set the status of the customer [pending/approved with credit/approved with out credit/ denied]
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const authenticated = useSelector((state) => state.UserReducer.isAuthenticated);
    const userRole = authenticated?.theUserRoles[0];
    console.log("COMPANY", company)
    const dispatch = useDispatch();
    const [role, setRole] = React.useState(null);

    const roleOptions = determineRoleOptions(userRole); 

    function handleChangeRole(event) {
        const newRole = event.target.value;
        setRole(newRole);
    }

    function updateUserRole(item){
        const selectedRoleOption = roleOptions.find(x => x.value == role);
        const roleName = selectedRoleOption.name;
        dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: roleName, CompanyId: company.id}});
    }

    return(
        <Box sx={style} className="statusBox">
            <Typography>Set Employee Role</Typography>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 2, minWidth: 120 }}>
                    <InputLabel htmlFor="demo-dialog-native">Role</InputLabel>
                    <Select
                        native
                        placeholder='Select a role'
                        value={role?.value}
                        onChange={handleChangeRole}
                    >
                        {roleOptions.map(x => {
                            return <option value={x.value}>{x.name}</option>
                        })}
                    </Select>
                    <Button onClick={()=>updateUserRole(item)}>Update</Button>
                </FormControl>
            </Box>
        </Box>
    )
}

export default UserRoleChange;